import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Categories, Versions } from './components';
import { colors } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DownloadIcon from '@mui/icons-material/Download';
import MuiLink from '@mui/material/Link';

let mock = {
  "ubuntu" : 'Ubuntu / Debain',
  "rhel":'Centos/RHEL 7',
  "linux":'General Linux',
  "freebsd":'FreeBSD',
  "openbsd":'OpenBSD',
  "mac":'MacOS',
  "android":'Android',
  "openwrt":'OpenWrt',
  "docker":'Docker' 
};
// let mockList =[];
const Release = () => {
  const [mockList, setMockList] = useState({});
  const theme = useTheme();
	var cos = null
	try {
		if(Object.keys(mockList).length == 0){
		const COS = require('./cos-js-sdk-v5.min.js');
		cos = new COS({
			SecretId: process.env.SecretId,
			SecretKey: process.env.SecretKey
		});
		cos.getBucket({
			Bucket: 'pipy-oss-1255617643', /* 必须 */
			Region: 'ap-beijing',     /* 必须 */
			Prefix: 'repo/pipy/'
		}, function(err, data) {
			if(data&&data.Contents){
				data.Contents.sort((a,b)=>{ 
					let a_ary = a.Key.replace('pipy-pjs','pipy').split("/");
					let a_fullFieldName = a_ary[a_ary.length -1];
					let a_versions = a_fullFieldName.split('-');
					let b_ary = b.Key.replace('pipy-pjs','pipy').split("/");
					let b_fullFieldName = b_ary[b_ary.length -1];
					let b_versions = b_fullFieldName.split('-');
					let rtn = false;
					if((a_versions[1]>b_versions[1])
					|| ((a_versions[1]==b_versions[1]) && !isNaN(a_versions[2]*1) && !isNaN(b_versions[2]*1) && a_versions[2]*1>b_versions[2]*1)
					|| ((a_versions[1]==b_versions[1]) && isNaN(a_versions[2]*1) && isNaN(b_versions[2]*1) && a_versions[2]>b_versions[2])
					|| ((a_versions[1]==b_versions[1]) && ((!isNaN(a_versions[2]*1) && isNaN(b_versions[2]*1))))
					){
						rtn = true;
					}
					return rtn ? -1:1
				})
				let _mockList ={};
				data.Contents.forEach((item) => {
					try {
						let _ary = item.Key.split("/");
						let fullFieldName = _ary[_ary.length -1];
						let fieldName = fullFieldName.replace('pipy-pjs','pipy');
						let attrs = fieldName.split("-");
						if(attrs.length == 5 && attrs[0]=="pipy" && attrs[1]!="nightly"){
							let platform = '';
							let _platform = attrs[3].split(".")[0].toLowerCase().replace("generic_","");
							let href = `https://pipy-oss-1255617643.cos-website.ap-beijing.myqcloud.com/${item.Key}`
							if(_platform.indexOf("el7")==0 || _platform.indexOf("rhel")==0){
								platform = mock["rhel"];
							} else if(_platform.indexOf("docker")==0 || _platform.indexOf("alpine")==0){
								platform = mock["docker"];
							} else if(_platform.indexOf("openwrt")==0){
								platform = mock["openwrt"];
							} else if(_platform.indexOf("mac")==0 || _platform.indexOf("darwin")==0){
								platform = mock["mac"];
							} else if(_platform.indexOf("openbsd")==0){
								platform = mock["openbsd"];
							} else if(_platform.indexOf("freebsd")==0){
								platform = mock["freebsd"];
							} else if(_platform.indexOf("android")==0){
								platform = mock["android"];
							} else if(_platform.indexOf("linux")==0){
								platform = mock["linux"];
							} else  if(_platform.indexOf("ubuntu")==0 || _platform.indexOf("debain")==0){
								platform = mock["ubuntu"];
							}
							if(!_mockList[platform]){
								_mockList[platform] = {
									list:[{platform,href,title:fullFieldName}],
									showMore:false
								}
							}else{
								_mockList[platform].list.push({platform,href,title:fullFieldName})
							}
						}
					} catch {
						/* do nothing */
					}
				})
				setMockList(_mockList);
			}else{
				console.log(err);
			}
		});
		}
	} catch {
		/* do nothing */
	}
	const toggleShowMore = (_key,value)=>{
		let _mockList ={...mockList};
		_mockList[_key].showMore = value;
		setMockList(_mockList);
	}
  return (
    <Main>
      <Container>
			
			  <Box>
			    <Box marginBottom={4}>
			      <Typography
			        variant={'h4'}
			        gutterBottom
			        align={'center'}
			        sx={{ fontWeight: 700 }}
			      >
			        All Releases
			      </Typography>
			    </Box>
			    <Grid container spacing={2}>
			      {Object.keys(mockList).map((_key) => (
			        <Grid item xs={12} key={_key}>
			          <Box
			            component={Card}
			            variant={'outlined'}
			            bgcolor={'transparent'}
			            sx={{
										paddingTop:1,
										paddingBottom:2,
			              '&:hover': {
			                boxShadow: 2,
			              },
			            }}
			          >
									<Box
										sx={{
											maxHeight:mockList[_key].showMore?'':'250px',
											overflow:mockList[_key].showMore?'':'hidden',
										}}
									>
									{mockList[_key].list.map((item,j)=> (
			            <Box component={CardContent} display={'flex'} alignItems={{ sm: 'center' }} style={{paddingTop:0,paddingBottom:0}}>
			              <Box
										  key={j}
			                display={'flex'}
			                flexDirection={{ xs: 'column', sm: 'row' }}
			                flex={'1 1 100%'}
			                justifyContent={{ sm: 'space-between' }}
			                alignItems={{ sm: 'center' }}
			              > 
										
			                
											<Typography
			                  variant={'h6'}
			                  fontWeight={700}
			                >
			                  {j==0?item.platform:null}
			                </Typography>
											
			                <Typography style={{lineHeight:'50px',borderTop:j>0?'1px solid rgba(240,240,240,0.6)':''}} component={'a'} href={item.href} target="_blank" variant={'subtitle1'} color={'text.secondary'}>
			                  {`${item.title}`}
			                </Typography>
											
			              </Box>
										<Box marginLeft={2} color={'primary.main'} component={'a'} href={item.href} target="_blank" >
											<DownloadIcon sx={{ cursor: 'pointer',color: 'currentColor',fontSize:'1.2em',position:'relative',top:3}}/>
											
										</Box>
			            </Box>
									))}
									</Box>
									
									{mockList[_key].list.length>5?
										<Box
											sx={{
												textAlign:'right',
												padding:'5px 20px 5px 20px'
											}}
										>
											{mockList[_key].showMore?
												<MuiLink sx={{cursor:'pointer'}} onClick={()=>{toggleShowMore(_key,false)}}>showLess</MuiLink>:
												<MuiLink  sx={{cursor:'pointer'}} onClick={()=>{toggleShowMore(_key,true)}}>showMore...</MuiLink>
											}
										</Box>
									:null}
			          </Box>
			        </Grid>
			      ))}
			    </Grid>
			  </Box>
      </Container>
    </Main>
  );
};

export default Release;
